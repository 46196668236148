// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-diskographie-js": () => import("/opt/build/repo/src/pages/diskographie.js" /* webpackChunkName: "component---src-pages-diskographie-js" */),
  "component---src-pages-duo-js": () => import("/opt/build/repo/src/pages/duo.js" /* webpackChunkName: "component---src-pages-duo-js" */),
  "component---src-pages-editionen-js": () => import("/opt/build/repo/src/pages/editionen.js" /* webpackChunkName: "component---src-pages-editionen-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-konzerte-js": () => import("/opt/build/repo/src/pages/konzerte.js" /* webpackChunkName: "component---src-pages-konzerte-js" */),
  "component---src-pages-ueber-uns-js": () => import("/opt/build/repo/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

